<template>
  <div>
    <div v-for="client in clientList" :key="client.id">
      <client-list-item :client="client" />
    </div>
    <div v-if="isEmptyList">
      <h5 class="text-center">Empty list</h5>
    </div>
  </div>
</template>

<script>
import ClientListItem from "./ClientListItem";
export default {
  components: {
    ClientListItem
  },
  computed: {
    clientList() {
      return this.$store.getters["clients/getClientList"];
    },
    isEmptyList() {
      return this.clientList.length === 0;
    }
  }
};
</script>

<style scoped></style>
