<template>
  <b-card no-body class="p-3 mb-2">
    <b-row class="no-gutters align-items-center">
      <b-col class="col-auto mr-3">
        <img :src="clientTypeImg" class="user__icon" />
      </b-col>
      <b-col>
        <div class="font-weight-bold">
          {{ clientFullName }}
        </div>
        <div class="small">
          <div v-if="!isClientMultiuser">
            <span class="mr-3">
              {{ client.registration.email }}
            </span>
            <span>{{ client.phone }}</span>
          </div>
          <div v-else>
            <span class="mr-3">{{ $t("multiUser") }}</span>
            <span class="mr-3">{{ clientCompanyName }}</span>
          </div>
        </div>
      </b-col>
      <b-col class="col-auto">
        <b-button class="mr-1 mb-1" size="sm" variant="outline-primary" @click="viewClientProjects">
          {{ $t("button.projects") }}
        </b-button>
      </b-col>
      <b-col v-if="isClientCustom" class="col-auto">
        <b-button class="mr-1 mb-1" size="sm" variant="outline-primary" @click="viewClientInvoices">
          {{ $t("button.invoices") }}
        </b-button>
      </b-col>
      <b-col class="col-auto">
        <b-button class="mb-1" size="sm" variant="outline-primary" @click="viewClientProfile">
          {{ $t("button.details") }}
        </b-button>
      </b-col>
      <b-col class="col-auto">
        <b-button class="mb-1 ml-1" size="sm" variant="outline-primary" @click="openServiceSelectionModal">
          {{ $t("button.addSubscription") }}
        </b-button>
      </b-col>
    </b-row>
    <select-services-modal :modalStatus="serviceModalStatus" @submit="subscribeToService"
      @setModalStatus="setServiceSelectionModalStatus">

    </select-services-modal>
  </b-card>
</template>

<script>
import Utils from "./../../Utils";
import SelectServicesModal from "../ServicesPredict/SelectServicesModal.vue";
export default {
  components: {
    SelectServicesModal
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      serviceModal: false,
    }
  },
  computed: {
    clientCompanyName() {
      return this.client.companyName;
    },
    clientFullName() {
      return this.client.firstName + " " + this.client.lastName;
    },
    isClientCustom() {
      return this.client.clientType === Utils.CLIENT_TYPE.CUSTOM;
    },
    isClientMultiuser() {
      return this.client.multiUser;
    },
    clientTypeImg() {
      if (this.isClientMultiuser) {
        return require("./../../assets/icons/users.svg");
      } else {
        return require("./../../assets/icons/user.svg");
      }
    },
    serviceModalStatus() {
      return this.serviceModal
    }
  },
  methods: {
    viewClientProjects() {
      this.$router.push({
        name: "ClientProjects",
        params: { id: this.client._id },
      });
    },
    viewClientInvoices() {
      this.$router.push({
        name: "ClientInvoices",
        params: { id: this.client._id },
      });
    },
    viewClientProfile() {
      this.$router.push({
        name: "EditClientAccount",
        params: { id: this.client._id },
      });
    },
    openServiceSelectionModal() {
      this.setServiceSelectionModalStatus(true)
    },
    setServiceSelectionModalStatus(status) {
      this.serviceModal = status
    },
    subscribeToService(serviceId) {
      const payload = {
        expirationDate: "2026-06-12T13:20:45.699Z",
        clientId: this.client._id,
        serviceId: serviceId

      }
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("subscriptionPredict/createSubscription", payload)
        .catch((e) => { console.log(e) })
        .finally(() => this.$store.dispatch("loader/close"));
    }
  },
};
</script>

<style scoped>
.user__icon {
  width: 20px;
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(173deg) brightness(87%) contrast(113%);
}
</style>
